<template>
	<el-container class="mainer">
		<el-aside :width="iscollapse ? '70px' : '240px'" class="asideClass">
			<el-scrollbar :native="false" style="height: 100vh;">
				<div>
					<!-- <img src="../../assets/logo.png" v-if="!iscollapse"> -->
					<h2 v-if="!iscollapse" class="system-title">智慧养老后台</h2>
				</div>
				<div class="user">
					<el-avatar :size="36" :src="userHead"></el-avatar>
					<div class="usertitle" v-if="!iscollapse">
						<p>{{ adminer }}</p>
					</div>
				</div>

				<el-menu router :default-active="activeIndex" background-color="#2F3E62" text-color="#ddd"
					:collapse="iscollapse" :mode="'vertical'" active-text-color="#ffd04b" unique-opened>
					<div v-for="item in menus" :key="item.id" class="menu">
						<el-submenu v-if="item.children" :index="item.name">
							<template slot="title">
								<div :class="iscollapse ? '' : 'flex align-items-c'">
									<i :class="item.icon"></i>
									<span v-if="!iscollapse">{{ item.title }}</span>
								</div>
							</template>
							<el-menu-item-group>
								<div v-for="subItem in item.children" :key="subItem.id">
									<el-submenu v-if="subItem.children" :index="subItem.name">
										<template slot="title">
											<div class="flex align-items-c">
												<i :class="subItem.icon"></i>
												<span>{{ subItem.title }}</span>
											</div>
											<el-badge :value="subItem.num" class="items"></el-badge>
										</template>
										<el-menu-item-group>
											<div v-for="thrItem in subItem.children" :key="thrItem.id">
												<el-submenu v-if="thrItem.children" :index="thrItem.name">
													<template slot="title">
														<div class="flex align-items-c">
															<i :class="thrItem.icon"></i>
															<span>{{ thrItem.title }}</span>
														</div>
													</template>
													<el-menu-item-group>
														<el-menu-item v-for="fthItem in thrItem.children" :key="fthItem.id" :index="fthItem.url">
															<div class="flex align-items-c">
																<i :class="fthItem.icon"></i>
																<span>{{ fthItem.title }}</span>
															</div>
														</el-menu-item>
													</el-menu-item-group>
												</el-submenu>
												<el-menu-item v-else :index="thrItem.url">
													<div class="flex align-items-c">
														<i :class="thrItem.icon"></i>
														<span>{{ thrItem.title }}</span>
													</div>
													<el-badge :value="thrItem.num" class="items"></el-badge>
												</el-menu-item>
											</div>
										</el-menu-item-group>
									</el-submenu>
									<el-menu-item :index="subItem.url" v-else>
										<div class="flex align-items-c">
											<i :class="subItem.icon"></i>
											<span>{{ subItem.title }}</span>
										</div>
									</el-menu-item>
								</div>
							</el-menu-item-group>
						</el-submenu>
						<el-menu-item v-else :index="item.url">
							<div :class="iscollapse ? '' : 'flex align-items-c'">
								<i :class="item.icon"></i>
								<span v-if="!iscollapse">{{ item.title }}</span>
							</div>
						</el-menu-item>
					</div>
				</el-menu>
			</el-scrollbar>
		</el-aside>

		<el-container>
			<el-header height="120">
				<div class="top-bar flex justify-content-f-e">
					<el-button @click="toCarLarge" size="mini" icon="el-icon-truck" style="background: transparent;color: #FFF;">
					</el-button>
					<el-button @click="toLarge" size="mini" icon="el-icon-data-line" style="background: transparent;color: #FFF;">
					</el-button>
					<el-button @click="extend" size="mini" icon="el-icon-full-screen"
						style="background: transparent;color: #FFF;">
					</el-button>
					<el-button @click="logout" size="mini" icon="el-icon-switch-button"
						style="background: transparent;color: #FFF;">
					</el-button>
				</div>

				<div class="page-tabs">
					<el-tabs v-model="activeIndex" type="card" closable @tab-click="tabClick" v-if="pageTabs.length"
						@tab-remove="tabRemove">
						<el-tab-pane :key="item.route" v-for="item in pageTabs" :label="item.meta.title" :name="item.route">
						</el-tab-pane>
					</el-tabs>
				</div>
			</el-header>

			<el-main>
				<transition name="slide-fade" mode="out-in">
					<!-- 最多缓存6个页面 -->
					<keep-alive :include="cachedPageNames" :max="6">
						<router-view></router-view>
					</keep-alive>
				</transition>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import screenfull from "screenfull";

	export default {
		data() {
			return {
				adminer: '',
				sex: '',
				// 是否全屏展开
				iscollapse: false,
				userHead: '',
			};
		},
		mounted() {
			this.adminer = sessionStorage.getItem("adminer")
			this.sex = sessionStorage.getItem("sex")
			this.userHead = sessionStorage.getItem('userHead')

			this.$util.get("user_permissions").then(res => {
				window.sessionStorage.setItem('roles', res.permissions)
				this.$store.commit("set_permssions", res.permissions)
				this.$store.commit("set_menus", res.menus)
			})

			if (this.$route.path != "/index" && this.$route.path != "/") {
				const tabNow = {
					route: this.$route.path,
					name: this.$route.name,
					meta: this.$route.meta,
					query: this.$route.query,
				};
				let flag = false
				this.$store.state.app.pageTabs.forEach((tab) => {
					if (tab.route == tabNow.route) {
						flag = true
					}
				})
				if (!flag) {
					this.$store.commit("set_active_index", this.$route.path);
					this.$store.commit("add_tabs", tabNow);
					this.$store.commit('addCachedPage', tabNow)
				}
			}
		},
		computed: {
			// 被缓存的页面
			cachedPageNames() {
				return this.$store.state.app.cachedPageNames
			},
			pageTabs() {
				return this.$store.state.app.pageTabs;
			},
			activeIndex: {
				get() {
					return this.$store.state.app.activeIndex;
				},
				set(val) {
					this.$store.commit("set_active_index", val);
				},
			},
			permissions() {
				return this.$store.state.app.permissions;
			},
			menus() {
				return this.$store.state.app.menus;
			},
		},
		watch: {
			// 当前路由改变，会引起当前选中菜单一起改变，tab和菜单引用了同一个变量
			$route(to) {
				let flag = false

				for (let option of this.pageTabs) {
					if (option.route === to.path) {
						flag = true;
						this.$store.commit("set_active_index", to.path);
						break;
					}
				}

				if (!flag) {
					const data = {
						route: to.path,
						name: to.name,
						meta: to.meta,
						query: to.query,
					}
					this.$store.commit("add_tabs", data)
					this.$store.commit('addCachedPage', data)
					this.$store.commit("set_active_index", to.path);
				}
			}
		},
		methods: {
			// tab切换时，动态的切换路由
			tabClick() {
				let path = this.activeIndex;
				if (this.$route.path != path) {
					this.$router.replace({
						path: path,
					});
				}
			},
			tabRemove(targetName) {
				// 首页不可删除
				if (targetName == '/index') return

				// 删除tab并销毁对应的界面
				const eligibleItem = this.$store.state.app.pageTabs.filter(item => {
					return item.route === targetName
				})
				// 后续可以改为异步
				this.$store.commit('delCachedPage', eligibleItem[0])
				this.$store.commit("delete_tabs", targetName)

				if (this.activeIndex === targetName) {
					// 设置当前激活的路由
					if (this.pageTabs && this.pageTabs.length >= 1) {
						this.$store.commit(
							"set_active_index",
							this.pageTabs[this.pageTabs.length - 1].route
						)
						this.$router.replace({
							path: this.activeIndex,
						})
					} else {
						this.$router.replace({
							path: '/index',
						})
					}
				}
			},
			/*
			 退出登录，清除一些数据，同时将活跃路由设置为待办事项
			 （原因是退出登录再重新登录会进入退出之前所在的页面，
			 但是路由却是待办事项的，这里给他固定重新登录进入待办事项）
			*/
			logout() {
				window.sessionStorage.removeItem("token")
				window.sessionStorage.removeItem("roles")
				window.sessionStorage.removeItem("adminer")
				window.sessionStorage.removeItem("sex")

				this.$store.commit("set_active_index", '/index')

				this.$router.push("/login")
			},
			// 展开屏幕
			extend() {
				if (!screenfull.isEnabled) {
					this.$message.error('当前不支持全屏')
				} else {
					this.iscollapse = !this.iscollapse;
					screenfull.toggle()
				}
			},
			// 前往大屏展示页面
			toLarge() {
				this.toLargeFun('/large-screen')
			},
			// 前往派车大屏
			toCarLarge() {
				this.toLargeFun('/car-statistics')
			},
			// 前往大屏
			toLargeFun(path) {
				var el = document.documentElement;
				el.requestFullscreen || el.mozRequestFullScreen || el.webkitRequestFullscreen || el.msRequestFullScreen ?
					el.requestFullscreen() || el.mozRequestFullScreen() || el.webkitRequestFullscreen() || el
					.msRequestFullscreen() : null;
				this.$router.push(path)
			},
		},
	}
</script>

<style scoped>
	/deep/ .el-scrollbar .el-scrollbar__wrap {
		overflow-x: hidden;
	}

	.slide-fade-enter-active {
		transition: all .3s;
	}

	.slide-fade-leave-active {
		transition: all .3s;
	}

	.slide-fade-enter {
		opacity: 0;
		transform: translateX(-10px);
	}

	.slide-fade-leave-to {
		opacity: 0;
		transform: translateX(10px);
	}

	.top-bar {
		font-size: 30px;
		padding: 10px 50px 10px 0;
	}

	.system-title {
		color: #FFF;
		text-align: center;
	}

	.mainer {
		height: 100vh;
	}

	.el-header {
		padding: 0;
	}

	.el-header,
	.el-aside {
		background-color: #2f3e62;
	}

	.items {
		margin-left: 10px;
	}

	.page-tabs {
		background-color: #fff;
		padding: 20px 20px 0;
	}

	.page-tabs /deep/ .el-tabs__header {
		margin-bottom: 0;
	}

	.user {
		padding: 10px;
		display: flex;
		align-items: center;
		margin-left: 10px;
	}

	.usertitle {
		margin-left: 10px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		color: #FFFFFF;
	}

	.page-tabs /deep/ .el-tabs__content {
		padding: 0;
	}

	.el-menu {
		border-right: none;
	}

	.el-footer {
		background-color: #f6f6f6;
	}

	.el-badge /deep/ .el-badge__content {
		border: none;
		margin-top: -4px;
	}

	.copy-content {
		font-size: 12px;
		line-height: 60px;
		color: #888;
	}

	.asideClass {
		transition: width .2s linear;
	}

	.submenuitem {
		width: 80px;
		overflow: hidden;
	}
</style>
<style>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}

	.menu {
		overflow: hidden;
	}
</style>